import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'gatsby';
import { Button, CircularProgress } from '@material-ui/core';
import qs from 'query-string';

import SEO from '../components/seo';

import * as AccountActions from '../actions/account-actions';

import styles from '../styles/confirm-email.module.scss';

const ConfirmEmailPage = ({ location, confirmEmail, resendConfirmEmail }) => {
    const [success, setSuccess] = useState(null);
    const [errors, setErrors] = useState(null);

    const resend = async () => {
        setErrors(
            [
                'Sorry, this confirm email link is expired.',
                'We just sent you a new one.'
            ]
        );

        const { id } = qs.parse(location.search);

        if (id) {
            await resendConfirmEmail(
                {
                    user_id: id
                }
            );
        }
    }

    const init = async () => {
        const { token } = qs.parse(location.search);

        if (!token) {
            await resend();
            return;
        }

        const result = await confirmEmail(
            {
                token
            }
        );

        if (result.ok) {
            setSuccess(['Thank you!', 'Your email is confirmed.']);
            return;
        }


        await resend();
    }

    useEffect(() => {
        init();
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const Content = () => {
        if (errors) {
            return (
                <ul className={styles.errors}>
                    {errors.map((x, i) => (
                        <li key={i}>{x}</li>
                    ))}
                </ul>
            );
        }

        if (success) {
            return (
                <>
                    <ul className={styles.success}>
                        {success.map((x, i) => (
                            <li key={i}>{x}</li>
                        ))}
                    </ul>
                    <Button
                        type='button'
                        variant='contained'
                        component={Link}
                        to='/'
                        color='primary'
                        size='large'
                    >
                        CONTINUE
                    </Button>
                </>
            );
        }

        return (
            <>
                <h5>Confirming your email...</h5>
                <CircularProgress size={48} />
            </>
        );
    };

    return (
        <>
            <SEO
                title='Confirm Email'
                path='/confirm-email/'
                description='Confirm your email for your Roselium account.'
            />
            <div className={styles.content}>
                <Content />
            </div>
        </>
    )
};

const mapDispatch = {
    confirmEmail: AccountActions.confirmEmail,
    resendConfirmEmail: AccountActions.resendConfirmEmail
};

export default connect(null, mapDispatch)(ConfirmEmailPage);
